<template>
  <div class="container-login">
    <a-button class="btn-back" @click="back()">
      <div class="d-flex">
        <img src="@/assets/images/arrowBack.svg" class="mr-1 iconlang" alt="" />
        {{ lbl['btn_back'] }}
      </div></a-button
    >
    <div class="box-login text-center">
      <!-- language -->
      <div class="language">
        <a-select
          id="forgot_password_select_language_option_box"
          v-model="currentLocale"
          class="select-language"
        >
          <a-select-option
            id="forgot_password_select_language_en_option"
            value="en"
            @click="switchLang('en')"
          >
            <img src="@/assets/images/en.svg" class="mr-2 iconlang" alt="" />ENG
          </a-select-option>
          <a-select-option
            id="forgot_password_select_language_th_option"
            value="th"
            @click="switchLang('th')"
          >
            <img src="@/assets/images/th.svg" class="mr-2 iconlang" alt="" />TH
          </a-select-option>
          <a-select-option
            id="forgot_password_select_language_ph_option"
            value="ph"
            @click="switchLang('ph')"
          >
            <img src="@/assets/images/ph.svg" class="mr-2 iconlang" alt="" />PH
          </a-select-option>
        </a-select>
      </div>
      <div class="mb-3 mt-30">
        <label class="text-h-forgot">{{
          lbl['new-login-forgot-password']
        }}</label>
        <label class="text-c-forgot">{{
          lbl['forgot-password-subheader']
        }}</label>
      </div>
      <div v-if="isSuccess" class="message-success w-100 mb-2">
        <a-alert type="success">
          <span slot="description" class="d-flex">
            <span class="lbl-success">{{
              lbl['forgot-password-complete-message']
            }}</span>
          </span>
        </a-alert>
      </div>
      <div v-if="isEmailNotnull" class="message-error w-100 mb-2">
        <a-alert type="error">
          <span slot="description" class="d-flex">
            <img src="@/assets/images/lock.svg" alt="" />
            <span class="lbl-error">{{
              lbl['forgot-password-email-required-message']
            }}</span>
          </span>
        </a-alert>
      </div>
      <div v-if="isErrorEmail" class="message-error w-100 mb-2">
        <a-alert type="error">
          <span slot="description" class="d-flex">
            <img src="@/assets/images/lock.svg" alt="" />
            <span class="lbl-error">{{
              lbl['forgot-password-email-invalid-format-message']
            }}</span>
          </span>
        </a-alert>
      </div>
      <div v-if="isAPIError" class="message-error w-100 mb-2">
        <a-alert type="error">
          <span slot="description" class="d-flex alert-error-api-container">
            <span class="lbl-error">
              {{ lbl['forgot-password-email-not-found'] }}
            </span>
            <img
              id="close-error-api-button"
              src="@/assets/images/close.svg"
              alt=""
              @click="closeError()"
            />
          </span>
        </a-alert>
      </div>
      <div v-if="isSuccess">
        <a-button
          id="forgot_password_login_button"
          class="btn_login"
          block
          @click="goLogin()"
        >
          {{ lbl['forgot-password-complete-login'] }}
        </a-button>
      </div>
      <div v-else>
        <a-form-item class="text-left">
          <div class="text-email">{{ lbl['user-permissions-email'] }}</div>
          <a-input
            id="forgot_password_email_input"
            v-model="email"
            :placeholder="lbl['forgot-password-email-placeholder-example']"
          >
          </a-input>
        </a-form-item>
        <a-button
          id="forgot_password_submit_button"
          class="btn-forgot-password"
          block
          @click="submitForgetpassword()"
        >
          {{ lbl['forgot-password-button'] }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Vue from 'vue'
import Locale from '@/helper/locale.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Cache from '@/helper/Cache.js'

export default {
  name: 'ForgotPassword',
  components: {},
  mixins: [Mixin],
  data() {
    return {
      currentLocale: 'en',
      email: '',
      isEmailNotnull: false,
      isErrorEmail: false,
      isAPIError: false,
      isSuccess: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.isSuccess = false
      this.currentLocale = Locale.getLocaleShort()
    },
    config() {
      return Vue.bzbsConfig
    },
    async switchLang(code) {
      this.handleLoading(true)
      this.updateLanguage(code)
      await this.setLang(code)
      this.$router.go()
    },
    updateLanguage(code) {
      var locale
      switch (code) {
        case 'th':
          locale = 1054
          break
        case 'en':
          locale = 1033

          break
        case 'ph':
          locale = 13321

          break
        default:
          locale = 1033
      }
      return new Promise(resolve => {
        BzbsProfile.updateLanguage(locale)
          .then(res => {
            var CRMUserCookie = Cache.get('CRMUser')
            CRMUserCookie.Token = res.data.data
            Cache.set('CRMUser', CRMUserCookie)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
    goLogin() {
      this.$router.push('/Account/Login')
    },
    submitForgetpassword() {
      this.handleLoading(true)
      this.isEmailNotnull = false
      this.isErrorEmail = false
      this.isAPIError = false
      this.isSuccess = false

      if (this.checkForm()) {
        var email = this.email
        return new Promise(resolve => {
          BzbsProfile.getForgetPassword(email)
            .then(res => {
              this.isSuccess = true
              this.handleLoading(false)
              resolve(res.data)
            })
            .catch(error => {
              this.handleLoading(false)
              this.isAPIError = true
              resolve(error)
            })
        })
      } else {
        this.handleLoading(false)
      }
    },
    checkForm() {
      if (!this.email) {
        this.isEmailNotnull = true
      } else {
        if (this.checkEmail(this.email)) {
          this.isErrorEmail = true
        }
      }

      if (!this.isEmailNotnull && !this.isErrorEmail) return true
    },
    checkEmail(e) {
      var value
      if (e.target) {
        value = e.target.value
      } else {
        value = e
      }
      if (this.validEmail(value)) {
        this.isErrorEmail = false
      } else {
        this.isErrorEmail = true
      }
    },
    validEmail: function (email) {
      const recheck =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return recheck.test(String(email).toLowerCase())
    },
    closeError() {
      this.isAPIError = false
    },
    back() {
      this.$router.push('/Account/Login')
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';

.box-login {
  position: absolute;
  top: 45%;
  left: 50%;
  border-radius: 12px;
  background-color: $color-white;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 48px 0 $color-black-2b;
  width: 445px;
  .title-login {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .ant-input {
    height: 40px;
    font-size: 15px;
  }

  .anticon svg {
    color: $color-black-32;
  }

  .btn_login,
  .btn-forgot-password {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 16px 0;
    padding: 10px;
    background-color: $primary;
    border: initial;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
  }
  .text-h-forgot {
    font-size: 20px;
    font-weight: 500;
    color: $text-dark;
  }
  .text-c-forgot {
    font-size: 14px;
    color: $color-grey-75;
  }
  .forgotpw {
    color: $color-blue-f3;
    text-decoration: underline;
  }
  .language {
    text-align: right;
  }
  .text-email {
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: $color-grey-42;
  }
  .alert-error-api-container {
    display: flex !important;
    justify-content: space-between;
  }
  .lbl-error {
    font-size: 14px;
    line-height: 1.43;
    color: $color-red-52;
    padding-left: 5px;
  }
  .lbl-success {
    font-size: 14px;
    line-height: 1.43;
    color: $primary-dark;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 5px;
  }
  .mt-30 {
    margin-top: 30px;
  }
}
.iconlang {
  width: 18px;
  height: 18px;
}
.btn-back {
  font-size: 16px;
  font-weight: bold;
  color: $color-grey-75 !important;
  background-color: $color-white !important;
  margin: 60px 0px 0px 100px;
}

@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .box-login {
    width: 80%;
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .box-login {
    width: 80%;
  }
}
</style>
